import React, { useContext, useEffect, useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';

import CurrencyContext from '../CurrencyContext';
import { useProducts } from '../SavingProductsContext';

interface IntlContext {
  t: (_id: string, _values?: { [key: string]: string | number }) => string;
  formatPercentage: (value: any) => string;
  formatMoney: (value: any) => string;
  formatAmount: (value: any) => string;
  formatMoneyShort: (value: any) => string;
  locale: string;
}

const IntlContext = React.createContext<IntlContext>({
  t: (id, values) => id,
  formatPercentage: (value) => value,
  formatMoney: (value) => value,
  formatAmount: (value) => value,
  formatMoneyShort: (value) => value,
  locale: 'en',
});

const REFERRAL_BONUS_PER_CURRENCY: any = {
  MXN: '100',
  EUR: '25',
  USD: '25',
  default: '25',
};

export const IntlContextProvider = injectIntl(
  ({ intl, children, ...props }) => {
    const { currency, setCurrency } = useContext(CurrencyContext);
    const { productList: products } = useProducts();
    const [globalVariables, setGlobalVariables] = useState({});
    const { locale } = intl;

    useEffect(() => {
      if (locale === 'es-MX' && currency !== 'MXN') {
        setCurrency('MXN');
      }
    }, [currency, locale, setCurrency]);

    const formatNumber = useCallback(
      (n, ...rest) => intl.formatNumber(parseFloat(n), ...rest),
      [intl],
    );

    const formatAmount = (amount: number) =>
      formatNumber(amount, { minimumFractionDigits: 2 });

    const formatMoney = (amount: number) =>
      formatNumber(amount, { style: 'currency', currency });

    const formatMoneyShort = useCallback(
      (amount) =>
        formatNumber(amount, {
          style: 'currency',
          currency,
          minimumFractionDigits: 0,
        }),
      [currency, formatNumber],
    );

    const formatPercentage = useCallback(
      (n) =>
        formatNumber(n / 100, {
          style: 'percent',
          maximumFractionDigits: 2,
        }).replace(/\s/g, ''),
      [formatNumber],
    );

    useEffect(() => {
      setGlobalVariables(
        products.reduce((acc, product: any, index) => {
          const values: any = {};

          const {
            productName,
            minimumInvestment: min,
            interestRate,
            years,
          } = product;
          const name = productName ? productName.replace('iban_', '') : '';

          values[`${name}_minimum`] = formatMoneyShort(min);
          values[`${name}_interest_rate`] = formatPercentage(interestRate);

          if (years) {
            values[`${name}_term`] = years;
          }

          // If it's the first product in the array, it's the one with the lowest
          // interest rate because they're ordered by interest rate
          if (!index) {
            values.minimum_interest_rate = formatPercentage(interestRate);
          } else if (index === products.length - 1) {
            values.maximum_interest_rate = formatPercentage(interestRate);
          }

          const sortBy = (key: string) => {
            return (a: any, b: any) =>
              a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
          };

          const productsByMinInvestment: any = products
            .concat()
            .sort(sortBy('minimumInvestment'));

          values.minimum_deposit_amount = formatMoneyShort(
            productsByMinInvestment[0]?.minimumInvestment ?? 1,
          );

          values.minimum_investment_amount = formatMoneyShort(
            productsByMinInvestment.filter((p: any) => !!p.years)[0]
              ?.minimumInvestment ?? 1000,
          );

          values.maximum_term =
            productsByMinInvestment.filter((p: any) => !!p.years).reverse()[0]
              ?.years ?? 5;

          return { ...acc, ...values };
        }, {}),
      );
    }, [formatMoneyShort, formatPercentage, products]);

    const t = (id: string, values = { defaultMessage: ' ' }) => {
      const { defaultMessage } = values;
      const key = id;

      if (process.env.NODE_ENV !== 'production' && typeof values === 'string') {
        // eslint-disable-next-line no-console
        console.warn('[intl] Using default value for', key);
      }

      // NOTE: If no translations are set yet
      if (Object.keys(intl.messages).length === 0) {
        return id;
      }

      return intl
        .formatMessage(
          { id: key, defaultMessage },
          {
            minimum_interest_rate: formatPercentage('2.5'),
            maximum_interest_rate: formatPercentage('6'),
            minimum_deposit_amount: formatMoneyShort('1'),
            minimum_investment_amount: formatMoneyShort('1000'),
            referral_bonus: formatMoneyShort(
              REFERRAL_BONUS_PER_CURRENCY[currency] ||
                REFERRAL_BONUS_PER_CURRENCY.default,
            ),
            ...values,
            ...globalVariables,
          },
        )
        .trim();
    };

    return (
      <IntlContext.Provider
        {...props}
        value={{
          ...intl,
          locale: intl.locale,
          t,
          formatPercentage,
          formatMoney,
          formatAmount,
          formatMoneyShort,
        }}
      >
        {children}
      </IntlContext.Provider>
    );
  },
);

export const useIntl = () => useContext(IntlContext);
