import React, { useState, useEffect, useContext } from 'react';
import ViewerContext from 'context/ViewerContext';

const localStorageCurrencyKey = 'curr';

const getCurrencyCode = (alpha3Code: string) => (currenciesList: string[]) => {
  const code = alpha3Code && currenciesList.find((c) => c === alpha3Code);
  return !!code;
};

const getLocalStorageCurrency = () =>
  sessionStorage.getItem(localStorageCurrencyKey);

const CurrencyContext = React.createContext<CurrencyContext>({
  currency: 'EUR',
  currenciesList: [],
  getLocalStorageCurrency,
  setCurrency: () => undefined,
});

export const CurrencyProvider: React.FC = ({ children }) => {
  const { allowedCurrencies } = useContext(ViewerContext);
  const [currency, setCurrency] = useState(allowedCurrencies[0]);

  const setCurrencyCode = (selectedCurrencyCode: string) => {
    setCurrency(selectedCurrencyCode);
    sessionStorage.setItem(localStorageCurrencyKey, selectedCurrencyCode);
  };

  useEffect(() => {
    const sessionStorageCurrency = getLocalStorageCurrency();
    if (
      sessionStorageCurrency &&
      getCurrencyCode(sessionStorageCurrency)(allowedCurrencies)
    ) {
      setCurrencyCode(sessionStorageCurrency);
    } else {
      setCurrencyCode(allowedCurrencies[0]);
    }
  }, [allowedCurrencies]);

  return (
    <CurrencyContext.Provider
      value={{
        currenciesList: allowedCurrencies,
        currency,
        setCurrency: setCurrencyCode,
        getLocalStorageCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyContext;
