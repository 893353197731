import React from 'react';
import { Link as UILink } from '@theme-ui/components';
import ExternalLink from './ExternalLink';
import LocalizedLink from './LocalizedLink';

interface LinkProps {
  to: string;
}

const Link: React.FC<LinkProps> = ({ children, to, ...rest }) => {
  const isExternalLink = /^(https?|mailto):/.test(to);

  if (isExternalLink) {
    return (
      <UILink {...rest} to={to} as={ExternalLink}>
        {children}
      </UILink>
    );
  }

  return (
    <UILink {...rest} to={to} as={LocalizedLink}>
      {children}
    </UILink>
  );
};

export default Link;
