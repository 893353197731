import React, { useContext } from 'react';
import { Location, WindowLocation, NavigateFn } from '@reach/router';
import queryString from 'query-string';

interface LocationContext {
  search: any;
  location?: WindowLocation;
  navigate?: NavigateFn;
}

const defaultValue = {
  search: {},
};

const LocationContext = React.createContext<LocationContext>(defaultValue);

export const LocationProvider: React.FC = ({ ...props }) => (
  <Location>
    {({ location, navigate }) => (
      <LocationContext.Provider
        {...props}
        value={{
          location,
          navigate,
          search: location.search ? queryString.parse(location.search) : {},
        }}
      />
    )}
  </Location>
);

export const useLocation = (): LocationContext => {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new TypeError('useLocation must be used within a LocationProvider');
  }
  return context;
};

export default LocationContext;
