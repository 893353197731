import React, { useContext, useEffect, useState } from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
import { IntlProvider } from 'react-intl';

import EN from 'assets/locales/en.json';
import LT from 'assets/locales/lt.json';

interface I18nContext {
  locale: string;
  defaultLocale: string;
  setLocale: (newLocale: string) => void;
}

const I18nContext = React.createContext<I18nContext>({
  locale: 'en',
  defaultLocale: 'en',
  setLocale: () => {},
});

interface I18nContextProviderProps {
  locale: string;
  defaultLocale: string;
}

export const I18nContextProvider: React.FC<I18nContextProviderProps> = ({
  children,
  locale: incomingLocale,
  defaultLocale,
}) => {
  const [locale, setLocale] = useState(incomingLocale);
  const [messages, setMessages] = useState<any>({});

  useEffect(() => {
    let isMounted = true;

    if (!isMounted) return;

    switch (locale) {
      case 'en':
        setMessages(EN);
        break;

      case 'lt':
        setMessages(LT);
        break;

      default:
        setMessages(EN);
        break;
    }

    return () => {
      isMounted = false;
    };
  }, [locale]);

  return (
    <I18nContext.Provider
      value={{
        locale,
        defaultLocale,
        setLocale: (newLocale) => {
          if (newLocale !== locale) {
            setLocale(newLocale);
          }
        },
      }}
    >
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={messages}
      >
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
};

export const useI18n = () => useContext(I18nContext);
