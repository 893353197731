import React from 'react';
import nest from 'recompose/nest';

import { Layout } from 'components/layout';
import { NavigationProvider } from 'context/NavigationContext';
import { IntlContextProvider } from 'context/IntlContext';


const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { hideHeaders, locale, name },
    location,
  } = props;

  if (hideHeaders && window.fcWidget) {
    window.fcWidget.destroy();
  }

  const AllPageProviders = nest(
    NavigationProvider,
    IntlContextProvider,
  );

  return (
    <AllPageProviders
      location={location}
      currentPageName={name}
    >
      <Layout locale={locale}>{element}</Layout>
    </AllPageProviders>
  );
};

export default wrapPageElement;
