import styled from '@emotion/styled';
import { animated } from 'react-spring';

const getHeaderHeight = () => {
  const height = document.getElementById("header")?.offsetHeight;
  return height;
};

export const Background = styled.div<{ usePrimaryBackground: boolean }>`
  align-items: center;
  background-color: ${({ theme, usePrimaryBackground }) =>
    usePrimaryBackground
      ? theme.colors.primaryBackground
      : theme.colors.tertiaryBackground};
  color: ${({ theme }) => theme.colors.quaternary};
`;

export const LogoContainer = styled.div<{ usePrimaryBackground: boolean }>`
  width: 100%;
  max-width: 168px;
  color: ${({ theme, usePrimaryBackground }) =>
    usePrimaryBackground ? theme.colors.quaternary : theme.colors.tertiary};
`;

export const Wrapper = styled.div`
  padding: 26px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileMenuWrapper = styled.div`
  position: relative;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 0;
    padding: 0;
    z-index: 2000;
  }
`;

export const MobileMenuIcon = styled.div`
  svg {
    color: ${({ theme }) => theme.colors.navigationPrimaryText};
  }
`;

export const MobileNav = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;

  background-color: ${({ theme }) =>
    theme.colors.navigationSecondaryBackground};
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.2);
`;

export const BackgroundShadow = styled(animated.div)`
  background: rgba(40, 39, 37, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 79px;
  right: 0;
  left: 0;
`;
