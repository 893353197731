/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FC, useState } from 'react';
import { useSpring } from 'react-spring';
import CloseIcon from 'images/icons/x.svg';

import { MobileMenuWrapper, MobileMenuIcon, MobileNav, BackgroundShadow } from './styled';
import MenuIcon from '../../images/icons/menu-icon.svg';

const MobileMenu: FC = ({ children }) => {
  const [opened, setOpened] = useState(false)

  const [animationProps, setSpring] = useSpring(() => ({
    opacity: 0,
    transform: `translateY(-120%)`,
  }));
  const setOpen = (show: boolean) => {
    setOpened(show)
    setSpring({
      opacity: show ? 1 : 0,
      transform: `translateY(${show ? '0px' : '-120%'})`,
    });
  };

  return (
    <MobileMenuWrapper
      sx={{
        display: ['flex', 'flex', 'flex', 'flex', 'none'],
        alignItems: 'center'
      }}
    >
      {opened ? (
        <button
        id="mobile-nav-close"
        onClick={() => setOpen(false)}
        type="button"
      >
        <CloseIcon
          sx={{
            color: 'navigationSecondaryText',
          }}
        />
      </button>
      ) : (<MobileMenuIcon>
        <button onClick={() => setOpen(true)} type="button">
          <MenuIcon />
        </button>
      </MobileMenuIcon>)}
      
      <BackgroundShadow style={animationProps}>
        <MobileNav>
          {children}
        </MobileNav>
      </BackgroundShadow>
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
