import React from 'react';
import nest from 'recompose/nest';

import { CurrencyProvider } from 'context/CurrencyContext';
import { I18nContextProvider } from 'context/I18nContext';
import { SavingsProductsProvider } from 'context/SavingProductsContext';
import { ViewerProvider } from 'context/ViewerContext';

import { LinksProvider } from 'context/LinksContext';
import { LocationProvider } from 'context/LocationContext';

const wrapRootElement = ({ element }) => {

  const AllRootProviders = nest(
    CurrencyProvider,
    LinksProvider,
    LocationProvider,
    SavingsProductsProvider,
    ViewerProvider,
    I18nContextProvider,
  );

  return (
     <AllRootProviders
      locale="en"
      defaultLocale="en">
      {element}
    </AllRootProviders>
  );
};

export default wrapRootElement;
