/** @jsx jsx */
import { jsx } from 'theme-ui';

interface ExternalLinkProps {
  href: string;
  to: string;
  variant?: string;
  sameTab?: boolean;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({
  children,
  to,
  sameTab,
  ...rest
}) => {
  const target = sameTab
    ? {}
    : { target: '_blank', rel: 'noopener noreferrer' };
  return (
    <a href={to} {...target} {...rest}>
      {children}
    </a>
  );
};

export default ExternalLink;
