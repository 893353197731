/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Link } from 'gatsby';
import { useIntl } from '../../context/IntlContext';

interface LocalizedLinkProps {
  to: string;
}

const LocalizedLink: React.FC<LocalizedLinkProps> = ({ to, ...props }) => {
  const { locale, defaultLocale } = useIntl();

  const linkLocale = locale === defaultLocale ? '' : `/${locale}`;

  return <Link {...props} to={`${linkLocale}${to}`} />;
};

export default LocalizedLink;
