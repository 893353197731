/** @jsx jsx */
import { jsx } from 'theme-ui';
import { alpha } from '@theme-ui/color';

import {
  FC,
  useRef,
  useEffect,
  useCallback,
  useState,
  useContext,
} from 'react';
import { Menu, MenuItem } from 'gatsby-theme-jane/Navigation';
import { Flex, Text, Box } from 'theme-ui';

import { useNavigation } from '../../context/NavigationContext';
import ViewerContext from '../../context/ViewerContext';
import { useIntl } from '../../context/IntlContext';
import ArrowIcon from '../../images/icons/green-arrow.svg';

import Link from '../Link';

type Variant = 'horizontal' | 'vertical';
interface NavigationMenuProps {
  variant?: Variant;
}

interface SubNavigationProps extends MenuItem {
  variant?: Variant;
}

const shouldShow = (
  item: MenuItem,
  locale: string,
  countryCode: string,
): boolean => {
  if (item.onlyCountries && item.onlyCountries?.length > 0) {
    if (item.onlyCountries?.includes(countryCode)) return true;
    return false;
  }

  if (!item.countryRestriction && !item.localeRestriction) return true;

  if (item.countryRestriction?.includes(countryCode)) return false;
  if (item.localeRestriction?.includes(locale)) return false;

  return true;
};

const SubNavigation: FC<SubNavigationProps> = ({
  label,
  to,
  menu,
  variant,
}) => {
  if (menu === undefined) {
    throw new TypeError(`Menu is undefined`);
  }

  const [dropdownVisible, toggleDropdown] = useState<boolean>(false);
  const listNode = useRef<HTMLLIElement>(null);
  const { countryCode } = useContext(ViewerContext);
  const { t, locale } = useIntl();

  const { type } = menu;

  const union = type === 'page' ? '/' : '#';
  const path = to ? `/${to}${union}` : '/';

  const handleClickOutside = useCallback((e) => {
    if (!listNode || listNode.current?.contains(e.target)) {
      return;
    }
    toggleDropdown(false);
  }, []);

  useEffect(() => {
    if (dropdownVisible && variant === 'horizontal') {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible, handleClickOutside, variant]);

  return (
    <li
      ref={listNode}
      sx={{
        display: 'flex',
        flexDirection: variant === 'vertical' ? 'column' : 'row',
        position: 'relative',
        listStyle: 'none',
        paddingX: 6,
        lineHeight: 'comfortable',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
        }}
      >
        <Text
          as="span"
          sx={{
            fontSize: 1,
            color: 'navigationSecondaryText',
            textDecoration: 'none',
            display: 'block',
          }}
          onClick={() => {
            toggleDropdown((state) => !state);
          }}
        >
          {t(label) || label}
        </Text>
        {variant === 'horizontal' && (
          <button
            key="button"
            type="button"
            onClick={() => {
              toggleDropdown((state) => !state);
            }}
            sx={{
              border: 'none',
              background: 'none',
              padding: 0,
              ml: 1,
              height: '24px',
              '&:focus': { outline: 0 },
            }}
          >
            <ArrowIcon
              sx={{
                color: 'navigationPrimaryText',
                transform: dropdownVisible ? 'rotate(180deg)' : 'none',
              }}
            />
          </button>
        )}
      </Flex>
      <ul
        sx={{
          display: dropdownVisible || variant === 'vertical' ? 'flex' : 'none',
          position: variant === 'vertical' ? 'initial' : 'absolute',
          flexDirection: 'column',
          top: '3rem',
          width: 'auto',
          p: 0,
          m: 0,
          listStyle: 'none',
          backgroundColor:
            variant === 'vertical'
              ? 'navigationSecondaryBackground'
              : 'primaryBackground',
          minWidth: variant === 'vertical' ? null : '12rem',
          borderRadius: variant === 'vertical' ? null : 2,
          borderStyle: variant === 'vertical' ? null : 'solid',
          borderWidth: variant === 'vertical' ? null : 1,
          borderColor: variant === 'vertical' ? null : 'secondaryBackground',
          zIndex: 1,
          '& > li': {
            borderBottom: variant === 'vertical' ? null : '1px solid',
            borderBottomColor:
              variant === 'vertical' ? null : 'secondaryBackground',
            ':last-child': {
              borderBottom: 'none',
            },
          },
        }}
      >
        {menu.items.map((item: any) => (
          <li
            sx={{
              alignItems: 'center',
              whiteSpace: 'nowrap',
              lineHeight: 'comfortable',
              paddingX: 5,
              '&:hover': {
                backgroundColor: 'secondary',
                a: {
                  color: 'primary',
                },
              },
            }}
            key={`${path}${item.to}`}
          >
            {shouldShow(item, locale, countryCode) && (
              <Link
                sx={{
                  color:
                    variant === 'vertical'
                      ? 'navigationSecondaryText'
                      : 'navigationSecondaryText',
                  textDecoration: 'none',
                  fontSize: 2,
                }}
                to={`${path}${item.to}`}
              >
                {t(item.label)}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </li>
  );
};

const NavigationMenu: FC<NavigationMenuProps> = ({
  variant = 'horizontal',
}) => {
  const { selectMenu } = useNavigation();
  const { menu } = selectMenu('main');
  const { t, locale } = useIntl();
  const { countryCode } = useContext(ViewerContext);

  return (
    <Box as="nav" sx={{ width: '100%', p: 0 }}>
      <Box
        as="ul"
        sx={{
          display: 'flex',
          flexDirection: variant === 'vertical' ? 'column' : 'row',
          listStyle: 'none',
          paddingX: 0,
          paddingY: variant === 'vertical' ? 0 : 3,
          m: 0,
          alignItems: 'center',
        }}
      >
        {menu.items.map((menuItem, index) =>
          menuItem.menu ? (
            <SubNavigation {...menuItem} variant={variant} key={menuItem.to} />
          ) : (
            <li
              key={menuItem.to}
              sx={{
                minWidth: variant === 'vertical' ? '100%' : '0',
                variant: 'navigation.listItem',
                display: 'flex',
                position: 'relative',
                listStyle: 'none',
                paddingX: 4,
                minHeight: '24px',
                lineHeight: 'comfortable',
                borderTop: variant === 'vertical' ? '1px solid #EBF5EC' : '0px',
              }}
            >
              {shouldShow(menuItem, locale, countryCode) && (
                <Link
                  sx={{
                    textAlign: 'center',
                    minWidth: variant === 'vertical' ? '100%' : '0',
                    color: 'navigationSecondaryText',
                    textDecoration: 'none',
                    fontSize: variant === 'vertical' ? 2 : 1,
                    lineHeight: '24px',
                    py: variant === 'vertical' ? 5 : 0,
                  }}
                  to={`/${menuItem.to}`}
                >
                  {t(menuItem.label)}
                </Link>
              )}
            </li>
          ),
        )}
      </Box>
    </Box>
  );
};

export default NavigationMenu;
