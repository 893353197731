/** @jsx jsx */
import { Container, jsx, Box, Button, Flex } from 'theme-ui';
import { FC } from 'react';

import { useIntl } from '../../context/IntlContext';

import Link from '../Link';
import Logo from '../Logo';

import MobileMenu from './MobileMenu';
import NavigationMenu from './NavigationMenu';
import LanguageSelector from '../LanguageSelector';
import { generateRandomUID } from 'utils';

export const Header: FC = () => {
  const { t } = useIntl();

  const signupLink: string = `${t(
    'app.signup.link',
  )}?session=${generateRandomUID()}`;
  const loginLink: string = `${t(
    'app.login.link',
  )}?session=${generateRandomUID()}`;

  return (
    <Box
      id="header"
      as="header"
      bg="navigationPrimaryBackground"
      sx={{
        paddingY: 2,
        position: 'sticky',
        top: 0,
        zIndex: 9,
      }}
    >
      <Container
        sx={{
          px: [
            '24px !important',
            '24px !important',
            '24px !important',
            '24px !important',
            '0 !important',
          ],
          maxWidth: '1024px',
        }}
      >
        <Flex sx={{ maxWidth: '100%', margin: 'auto' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Link
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              to="/"
            >
              <Logo
                sx={{
                  color: 'navigationPrimaryText',
                }}
              />
            </Link>
          </Box>
          <Box
            sx={{
              display: ['none', 'none', 'none', 'none', 'flex'],
              justifyContent: 'space-between',
              flex: '1',
            }}
          >
            <Box
              sx={{
                display: ['flex'],
                alignItems: 'center',
                justifyContent: 'flex-end',
                ml: 'auto',
              }}
            >
              <NavigationMenu />
              <Box sx={{ px: 4 }}>
                <LanguageSelector />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: ['none', 'none', 'none', 'none', 'flex'],
              alignItems: 'center',
              justifyContent: 'flex-end',
              ml: 'auto',
            }}
          >
            <Flex sx={{}}>
              <Link
                sx={{
                  textDecoration: 'none',
                  color: 'primary',
                  appearance: 'none',
                  textAlign: 'center',
                  px: 6,
                  py: 3,
                  mr: [0, 0, 0, 0, 6],
                  fontWeight: 600,
                }}
                to={loginLink}
              >
                {t('homepage.header.login').toUpperCase()}
              </Link>
              <Button
                sx={{ maxHeight: '44px' }}
                as={Link}
                variant="primary"
                to={signupLink}
              >
                {t('homepage.header.signup').toUpperCase()}
              </Button>
            </Flex>
          </Box>
          <Box
            sx={{
              display: ['flex', 'flex', 'flex', 'flex', 'none'],
              alignItems: 'center',
              justifyContent: 'flex-end',
              ml: 'auto',
            }}
          >
            <LanguageSelector fixed />
          </Box>
          <MobileMenu>
            <NavigationMenu variant="vertical" />
          </MobileMenu>
        </Flex>
      </Container>
    </Box>
  );
};
