import axios from 'axios';

const apiBaseURL =
  process.env.GATSBY_API_URL || 'https://api.dev.ibanwallet.com/';

const APPLICATION_NAME = 'barney';

axios.interceptors.request.use((config) => {
  if (config.method === 'get') {
    // eslint-disable-next-line no-param-reassign
    config.headers.pragma = 'no-cache';
    // eslint-disable-next-line no-param-reassign
    config.headers['cache-control'] = 'no-cache';
    // eslint-disable-next-line no-param-reassign
    config.headers['x-application-name'] = APPLICATION_NAME;
  }
  return config;
});

const submitEmail = async ({
  email,
  locale,
  profileType,
  login,
  signup,
}: SubmitEmailRequestParams) => {
  const encodedEmail = encodeURIComponent(email);

  try {
    const token = (
      await axios.post(
        `${apiBaseURL}api/v1/registration/email`,
        { sendEmailVerificationEmail: false, email },
        {
          params: { locale, profileType },
          headers: { 'x-application-name': APPLICATION_NAME },
        },
      )
    ).data.data;

    window.location.replace(`${signup}&token=${token}&email=${encodedEmail}`);
    return '';
  } catch (err) {
    const errorCode =
      err?.response?.data?.meta?.errorCode ?? 'generic.try_again';

    if (errorCode === 'ALREADY_EXISTS') {
      window.location.replace(`${login}&email=${encodedEmail}`);
      return '';
    }

    return errorCode;
  }
};

const viewerCountry = async (): Promise<Country | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.get(`${apiBaseURL}api/v1/countries/viewer`);
    return data;
  } catch (err) {
    return undefined;
  }
};

const countriesList = async (): Promise<Country[] | undefined> => {
  try {
    const {
      data: { data },
    } = await axios.get(`${apiBaseURL}api/v1/countries/all?language=en`);
    return data;
  } catch (err) {
    return undefined;
  }
};

const getProducts = async (
  countryCode: string,
  currency: string,
): Promise<Product[] | null> => {
  try {
    return (
      await axios.get(
        `${apiBaseURL}api/v1/product/country/${countryCode}?currencyAlpha3Code~eq=${currency}&sortBy=interestRate&sortDir=asc`,
      )
    ).data.data.values;
  } catch (err) {
    return null;
  }
};

const getRecurringProducts = async (
  productName: string,
  currencyCode: string,
  countryCode: string,
  initialInvestment: number,
  monthlyInstallment: number,
  adjustInflation: boolean,
  termPeriodYears: number,
): Promise<Product | null> => {
  try {
    return await axios.post(
      `${apiBaseURL}api/v1/recurringInstallmentProduct/preview`,
      {
        productName,
        currencyCode,
        countryCode,
        initialInvestment,
        monthlyInstallment,
        adjustInflation,
        termPeriodYears,
      },
    );
  } catch (err) {
    return null;
  }
};

const requestLoan = async (payload: RequestLoanParams) => {
  try {
    await axios.post(
      `${apiBaseURL}api/v1/loan`,
      payload,
      { headers: { 'x-application-name': APPLICATION_NAME } },
    );

    return true;
  } catch (err) {
    return false;
  }
};

export {
  submitEmail,
  viewerCountry,
  countriesList,
  getProducts,
  getRecurringProducts,
  requestLoan,
};
