import React, { FC } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';

import Selector from './selector';
import { useI18n } from 'context/I18nContext';

const query = graphql`
  query LanguageSelectorQuery {
    allSitePage {
      nodes {
        path
        pageContext 
      }
    }
    allLokaliseLanguageList {
      nodes {
        lang_name
        lang_iso
        lang_id
      }
    }
  }
`;

interface LanguageSelectorProps {
  fixed?: boolean;
}
const LanguageSelector: FC<LanguageSelectorProps> = ({ fixed = false }) => {
  const { locale, defaultLocale, setLocale } = useI18n();

  const data: LanguageSelectorData = useStaticQuery(query);

  const { allLokaliseLanguageList } = data;

  const onLanguageChange = (newLanguage: string) => {
    if (newLanguage !== locale) {
      let newPath: string;
      const currentPath: Array<string> = window.location.pathname.split('/');
      // If it's comming back to the default locale
      if (newLanguage === defaultLocale) {
        currentPath.splice(1, 1);
        newPath = `${currentPath.join('/')}`;
      } else {
        // If it's going to another language from default language
        if (locale === defaultLocale) {
          newPath = `/${newLanguage}${window.location.pathname}`;
        } else {
          // It's comming from language a to b and neither of them are the default
          currentPath.splice(1, 1, newLanguage); 
          newPath = `${currentPath.join('/')}`;
        }
      }
      navigate(newPath);
    }
  }

  return (
    <Selector
      options={allLokaliseLanguageList?.nodes}
      onChange={onLanguageChange}
      value={locale ? locale : defaultLocale}
      label={locale ? locale?.toUpperCase() : defaultLocale.toUpperCase()}
      fixed={fixed}
    />
  );
};

export default LanguageSelector;
