/** @jsx jsx */
import { Container, Box, Flex, Text, jsx } from 'theme-ui';
import { FC, useContext } from 'react';

import { NavMenu, MenuItem } from 'gatsby-theme-jane/Navigation';
import { useNavigation } from '../../context/NavigationContext';
import { useIntl } from '../../context/IntlContext';
import ViewerContext from '../../context/ViewerContext';

import Link from '../Link';
import Logo from '../Logo';
import BankOfLithuaniaLogo from './images/bank_of_lithuania.svg';

import ArrowRight from './icons/right-arrow.svg';

interface MenuProps {
  item: MenuItem | NavMenu;
  before?: JSX.Element;
  after?: JSX.Element;
}

const Menu: FC<MenuProps> = ({ item, before, after, ...props }) => {
  const { t, locale } = useIntl();
  const { countryCode } = useContext(ViewerContext);
  const { menu } = item;
  const union = menu.type === 'tab' ? '' : '/';
  const path = item?.to ? `/${item.to}` : '';

  const shouldShow = (
    item: MenuItem,
    locale: string,
    countryCode: string,
  ): boolean => {
    if (item.onlyCountries && item.onlyCountries?.length > 0) {
      if (item.onlyCountries?.includes(countryCode)) return true;
      return false;
    }

    if (!item.countryRestriction && !item.localeRestriction) return true;

    if (item.countryRestriction?.includes(countryCode)) return false;
    if (item.localeRestriction?.includes(locale)) return false;

    return true;
  };

  return (
    <ul {...props}>
      {before}
      {menu.items.map((i: MenuItem, index: number) => {
        if (!i.hidden && shouldShow(i, locale, countryCode))
          return (
            <li key={i.label}>
              {i.menu ? (
                t(i.label)
              ) : (
                <Link
                  key={i.label}
                  to={t(`${i.external}`) || `/${path}${union}${i.to}`}
                >
                  <Box
                    sx={{
                      py: [2, 2, 2, 2, 0],
                      borderTop:
                        index === 0
                          ? '0px'
                          : [
                              '1px solid rgba(40, 39, 37, 0.1)',
                              '1px solid rgba(40, 39, 37, 0.1)',
                              '1px solid rgba(40, 39, 37, 0.1)',
                              '1px solid rgba(40, 39, 37, 0.1)',
                              '0px',
                            ],
                      m: '0',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    {t(i.label)}
                    <ArrowRight
                      sx={{
                        width: '12px',
                        height: '12px',
                        my: '6px',
                        mx: 2,
                        display: ['flex', 'flex', 'flex', 'flex', 'none'],
                      }}
                    />
                  </Box>
                </Link>
              )}
              {i.menu && <Menu item={i} />}
            </li>
          );
      })}
      {after}
    </ul>
  );
};

export const Footer: FC = ({ children }) => {
  const { selectMenu } = useNavigation();
  const { t } = useIntl();
  const appVersion = process.env.GATSBY_APP_VERSION;

  const menu = selectMenu('footer');

  return (
    <Box
      as="footer"
      bg="lightNeutral"
      sx={{
        paddingY: 11,
      }}
    >
      <Container
        sx={{
          px: [
            '24px !important',
            '24px !important',
            '24px !important',
            '24px !important',
            '0 !important',
          ],
          maxWidth: '1024px',
        }}
      >
        <Flex>
          <Box
            as="nav"
            id="footer"
            sx={{
              width: '100%',
              maxWidth: '100%',
              margin: 'auto',
            }}
          >
            <Menu
              item={menu}
              before={
                <li
                  sx={{
                    width: ['100%', '100%', '100%', '100%', '35%'],
                    minWidth: ['0', '0', '0', '0', '358px !important'],
                    pt: '0 !important',
                  }}
                >
                  <Link to="/">
                    <Logo
                      sx={{
                        maxWidth: '8rem',
                        color: 'quaternary',
                      }}
                    />
                  </Link>
                  <Box
                    sx={{
                      fontSize: 2,
                      color: 'mediumNeutral',
                      fontWeight: '400',
                      maxWidth: ['100%', '100%', '100%', '100%', '288px'],
                      pt: [9, 9, 9, 6, 6],
                    }}
                  >
                    <Text sx={{ display: 'block' }}>
                      {t('homepage.footer.contacts.email_label')}
                    </Text>
                    <Text
                      sx={{
                        fontWeight: '600',
                        display: 'block',
                        pb: 7,
                      }}
                    >
                      {t('homepage.footer.contacts.email_value')}
                    </Text>
                    <Text sx={{ display: 'block' }}>
                      {t('homepage.footer.contacts.phone_label')}
                    </Text>
                    <Text
                      sx={{
                        fontWeight: '600',
                        display: 'block',
                        pb: 7,
                      }}
                    >
                      {t('homepage.footer.contacts.phone_value')}
                    </Text>
                    <Text sx={{ display: 'block' }}>
                      {t('homepage.footer.contacts.address_label')}
                    </Text>
                    <Text
                      sx={{
                        fontWeight: '600',
                        display: 'block',
                        pb: 0,
                      }}
                    >
                      {t('homepage.footer.contacts.address_value')}
                    </Text>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <BankOfLithuaniaLogo
                      sx={{ mt: ['60px', '60px', '60px', '60px', '92px'] }}
                    />
                    <Text
                      sx={{
                        color: 'bankOfLithuania',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '24px',
                        mt: 2,
                      }}
                    >
                      {t('homepage.footer.social_proof')}
                    </Text>
                  </Box>
                </li>
              }
              sx={{
                display: 'flex',
                flexDirection: ['column', 'column', 'column', 'column', 'row'],
                // justifyContent: 'space-between',
                listStyle: 'none',
                p: 0,
                m: 0,
                '& > li': {
                  p: 0,
                  pt: [9, 9, 9, 9, 0],
                  color: 'rgba(40, 39, 37, 0.3)',
                  fontSize: 2,
                  fontWeight: '600',
                  minWidth: ['0', '0', '0', '0', '234px'],
                },
                '& > li > ul': {
                  pt: 5,
                },
                '& > li > a': {
                  color: 'septenary',
                },
                '& > li > ul > li': {
                  pt: [0, 0, 0, 0, 2],
                  '&:hover': {
                    a: {
                      color: 'primary',
                    },
                  },
                },
                '& > li > ul > li > a': {
                  color: 'quinary',
                },
                '& ul': {
                  listStyle: 'none',
                  m: 0,
                  py: '0',
                  px: 0,
                  fontSize: [2, 2, 2, 2, 1],
                  fontWeight: '400',
                },
              }}
            />
          </Box>
        </Flex>
        <Box
          sx={{
            maxWidth: '100%',
            m: 'auto',
          }}
        >
          <Box
            sx={{
              fontSize: 0,
              color: 'mediumNeutral',
              mt: [12, 12, 12, 12, 12],
            }}
          >
            <Flex sx={{ flexDirection: 'column' }}>
              <Text sx={{ p: 0, m: 0 }}>
                {t('homepage.footer.trademark', {
                  year: new Date().getFullYear(),
                })}
              </Text>
              <Text sx={{ p: 0, m: 0 }}>
                  v{appVersion || '0.0.0'}
              </Text>
            </Flex>
            <Text sx={{ p: 0, m: 0 }}>{t('homepage.footer.cookies')}</Text>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
