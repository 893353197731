import { Locale } from 'locales/Locale';

export const buildCanonical = (
  lang: Locale,
  defaultLocale: string,
  baseURL: string,
  path: string,
) => {
  const finalPath = path === '' ? path : `${path}/`;
  return lang.lcid === defaultLocale
    ? `${baseURL}/${finalPath}`
    : `${baseURL}/${lang.path}/${finalPath}`;
};
